<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-20">
			<span class="a-fs-16">样例页面</span>
			<el-button icon="el-icon-upload2" class="s-btn-exp">导出</el-button>
		</div>

		<el-card class="el-main">
			<div>常规大小</div>
			<le-search-form>
				<le-input label="不带ICON" v-model="input2" placeholder="请输入内容" />
				<le-input-icon-search label="搜索图标" v-model="input2" placeholder="请输入内容" :prefixIcon="prefixicon" />
				<le-input-num-range label="数字区间" :minData.sync="input2" :maxData.sync="input3" placeholder="订单量" />
				<le-input-price-range label="金额区间" :minData.sync="input2" :maxData.sync="input3" placeholder="金额(元)" />
				<le-select-num-range label="范围选择" :minData.sync="input2" :maxData.sync="input3"
					:options="numRangeOptions" unit="%"></le-select-num-range>
				<le-select-local-search label="普通选择" v-model="input2" :options="options" />
				<le-select-remote-search label="远程搜索" v-model="input2" :options="ownerCompanyOptions"
					placeholder="请选择（可搜索）" notnull />
				<le-date-range label="日期范围" :minDate.sync="input2" :maxDate.sync="input3" />
				<le-input-date label="日期选择" v-model="input2"></le-input-date>
				<le-input-address label="详细地址" :province.sync="province" :city.sync="city" :area.sync="area"
					:address.sync="address"></le-input-address>
				<le-input-address-point label="详细地址" :province.sync="province" :city.sync="city" :area.sync="area"
					:address.sync="address" :lat.sync="input2" :lng.sync="input3"></le-input-address-point>
				<le-input-district label="省市区" :province.sync="province" :city.sync="city" :area.sync="area">
				</le-input-district>
				<le-company-all-select label="全部商户" v-model="input2"></le-company-all-select>
				<le-company-under-select label="下级商户" v-model="input2"></le-company-under-select>
				<le-company-noagency-select label="未签协议商户" v-model="input2"></le-company-noagency-select>
				<le-switch-btn label="选择按钮" v-model="input2" :options="typeOptions"></le-switch-btn>
				<le-upload-file label="上传文件" v-model="input2"></le-upload-file>

			</le-search-form>

			<div>大号尺寸</div>
			<le-search-form>
				<le-input label="不带ICON" v-model="input2" placeholder="请输入内容" large notnull />
				<le-input-icon-search label="搜索图标" v-model="input2" placeholder="请输入内容" large />
				<le-input-num-range label="数字区间" :minData.sync="input2" :maxData.sync="input3" placeholder="订单量" large
					notnull />
				<le-input-price-range label="金额区间" :minData.sync="input2" :maxData.sync="input3" placeholder="金额(元)"
					large />
				<le-select-local-search label="普通选择" v-model="input2" :options="options" large />
				<le-date-range label="日期范围" :minDate.sync="input2" :maxDate.sync="input3" large />
				<le-select-remote-search label="远程搜索" v-model="input2" :options="ownerCompanyOptions"
					placeholder="请选择（可搜索）" notnull large />
				<le-input-address label="详细地址" :province.sync="province" :city.sync="city" :area.sync="area"
					:address.sync="address" large />
			</le-search-form>


			<el-tabs>
				<el-tab-pane label="运营中" name="2"></el-tab-pane>
				<el-tab-pane label="申报中" name="0"></el-tab-pane>
				<el-tab-pane label="建设中" name="1"></el-tab-pane>
				<el-tab-pane label="已撤站" name="3"></el-tab-pane>
			</el-tabs>

			<le-pagview @setData="setTableData" :pageParam="pageParam">
				<el-table :data="tableData" :highlight-current-row="true">
					<el-table-column prop="orderId" label="订单号" width="150" align="center">
					</el-table-column>
					<el-table-column label="设备" align="center">
						<template slot-scope="{ row }">
							<span>{{ row.deviceCode }}-{{ row.slot }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="stationName" label="站点" width="150" align="center">
					</el-table-column>
					<el-table-column prop="userName" label="用户" align="center">
					</el-table-column>
					<el-table-column prop="mobile" label="手机号" align="center">
					</el-table-column>
					<el-table-column prop="orderSourceTypeText" label="来源" align="center">
					</el-table-column>
					<el-table-column prop="payTypeText" label="支付方式" align="center">
					</el-table-column>
					<el-table-column prop="activityBalance" label="红包" align="center">
						<template slot-scope="{ row }">
							<span>{{ util.numFormat(row.amount) }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="statusText" label="订单状态" align="center">
					</el-table-column>
					<el-table-column label="计划时长" align="center">
						<template slot-scope="{ row }">
							<span>{{ row.planMinutes }}分钟</span>
						</template>
					</el-table-column>
					<el-table-column label="实际时长" align="center">
						<template slot-scope="{ row }">
							<span>{{ row.realMinutes }}分钟</span>
						</template>
					</el-table-column>

					<el-table-column label="操作" align="center">
						<template slot-scope="{ row }">
							<el-tooltip class="item" effect="dark" content="订单详细">
								<img src="../../assets/icon/option-detail.png" class="a-wh-16" />
							</el-tooltip>
							<el-tooltip class="item a-ml-12" effect="dark" content="订单退款" v-if="row.statusRefund == 0">
								<img src="../../assets/icon/option-refund.png" class="a-wh-16" />
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
			</le-pagview>
		</el-card>
	</div>
</template>

<script>
import util from "../../utils/util.js";
export default {
	mounted() { },
	data() {
		return {
			util: util,
			activeName: "2",
			input2: "",
			input3: "",
			prefixicon: "",
			options: [{
				value: '选项1',
				label: '黄金糕'
			}, {
				value: '选项2',
				label: '双皮奶'
			}, {
				value: '选项3',
				label: '蚵仔煎'
			}, {
				value: '选项4',
				label: '龙须面'
			}, {
				value: '选项5',
				label: '北京烤鸭'
			}],
			numRangeOptions: [{
				minData: 5,
				maxData: 30
			},
			{
				minData: 31,
				maxData: 60
			},
			{
				minData: 61,
				maxData: 95
			}

			],
			tableData: [],
			pageParam: {
				url: this.$Config.apiUrl.getOrderList,
				method: "post",
				params: {
					mobile: "",
					deviceCode: "",
					startTime: "",
					endTime: "",
					mobile: "",
					stationName: "",
				},
			},

			ownerCompanyOptions: {
				url: this.$Config.apiUrl.getUnderCompany,
				method: "post",
				params: {
					isSignAgency: 1,
				},
				labelKey: "name",
				valueKey: "id",
				searchKey: "key",
			},

			province: '',
			city: '',
			area: '',
			address: '',
			lat: '',
			lng: '',

			typeOptions: [{
				value: '0',
				label: '自营'
			},
			{
				value: '1',
				label: '代建'
			}
			],
		}


	},

	watch: {
		province: {
			handler(val, oldval) {
				console.log('user.vue province change', val)
			}
		}
	},
	mounted() { },
	methods: {
		//获取车辆列表
		setTableData(data) {
			this.tableData = data;
		},
	},
};
</script>

<style scoped>
</style>
